import { IoIosArrowForward } from "react-icons/io";

const SettingsCard = ({ title, info, icon, color, onClick, className }) => {
  return (
    <div className="settings-card-container">
      <div className="card-icon-container">
        <div style={{ backgroundColor: color }} className="img-bcg">
          <img src={icon} alt="" />
        </div>

        <div className="cardInfo" onClick={() => onClick()}>
          <h3 className={className}>{title}</h3>
          <span>{info}</span>
        </div>
      </div>

      <IoIosArrowForward className="go-to-icon" onClick={() => onClick()} />
    </div>
  );
};
export default SettingsCard;
