import React, { useState } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "../ProfilePage/ProfilePage.css";
import AdditionalDetailsComponent from "../../components/AdditionalDetailsComponent/AdditionalDetailsComponent";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useLocalState } from "../../utils/useLocalStorage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ProfilePage = ({ userName }) => {
  const [jwt] = useLocalState("", "jwt");
  const [first_name, setFirstName] = useState(userName.firstName || "");
  const [last_name, setLastName] = useState(userName.lastName || "");
  const [other_name, setOthername] = useState(userName.otherName || "");
  const [gender, setGender] = useState(userName.gender || "");
  const [reg_email] = useState(userName.reg_email || "");
  const [reg_phone] = useState(userName.reg_phone || "");
  const [state] = useState(userName.state || "");
  const [loading, setLoading] = useState(false);

  const handleFormUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);

    const updatedData = {
      first_name,
      last_name,
      other_name,
      gender,
      reg_email,
      reg_phone,
      state,
    };

    try {
      const response = await axios.post(
        "https://leverpay-api.azurewebsites.net/api/v1/user/update-user-profile",
        updatedData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Profile updated successfully!");
      } else {
        toast.error("Something went wrong");
      }
      console.log(response.data);
    } catch (error) {
      console.log("Error updating profile", error);
      toast.error("Failed! Try again");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div col-12 className="outer">
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeButton={true}
      />
      <Tabs>
        <TabList>
          <Tab>Basic Information</Tab>
          <Tab>Bank Details</Tab>
        </TabList>

        <TabPanel>
          <Form col-md-8 className="inner-form" onSubmit={handleFormUpdate}>
            <div className="fields-control">
              <Form.Group className="mb-3" controlId="">
                <Form.Control
                  type="text"
                  name="first_name"
                  value={first_name}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="First Name"
                  className="profile-text-area"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="">
                <Form.Control
                  type="text"
                  name="last_name"
                  value={last_name}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder={userName.lastName}
                  className="profile-text-area"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="">
                <Form.Control
                  type="text"
                  name="other_name"
                  value={other_name}
                  onChange={(e) => setOthername(e.target.value)}
                  placeholder="Other Name"
                  className="profile-text-area"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="">
                <Form.Select
                  aria-label="Default select example"
                  name="gender"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  className="profile-text-area "
                >
                  <option value="">Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="">
                <Form.Control
                  type="email"
                  value={reg_email}
                  className="profile-text-area"
                  readOnly
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="">
                <Form.Control
                  type="text"
                  value={reg_phone}
                  className="profile-text-area default-number"
                  readOnly
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="">
                <Form.Control
                  type="text"
                  value={state}
                  className="profile-text-area default-number"
                  readOnly
                />
              </Form.Group>
              <div className="button-control">
                <Button
                  variant="primary"
                  type="submit"
                  className="update-button"
                  disabled={loading}
                >
                  {loading ? "Updating" : "Update Profile"}
                </Button>
              </div>
            </div>
          </Form>
        </TabPanel>
        <TabPanel>
          <AdditionalDetailsComponent />
        </TabPanel>
      </Tabs>
    </div>
  );
};
export default ProfilePage;
