import { useState } from "react";
import "./Settings.css";
import { IoIosArrowBack } from "react-icons/io";
import { TbLockFilled } from "react-icons/tb";
import SettingsCard from "./SettingsCards";
import Edit from "../../assets/Edit-Square.png";
import Copy from "../../assets/copy-icon.png";
import ProfileIcon from "../../assets/profile-icon.png";
// import BellIcon from "../../assets/bell-icon.png";
import SecurityIcon from "../../assets/security-icon.png";
import HelpIcon from "../../assets/help-icon.png";
import ReferralIcon from "../../assets/refer-icon.png";
import MerchantIcon from "../../assets/merchant-icon.png";
import Avatar from "../../assets/avatar-img.jpeg";
import MerchantModal from "../MerchantModal/MerchantModal";
import { useNavigate } from "react-router-dom";

const Settings = ({ userName }) => {
  const navigate = useNavigate();
  const [showMerchantModal, setShowMerchantModal] = useState(false);

  const lastInitial =
    userName.lastName && userName.lastName.charAt(0).toUpperCase() + ".";

  const CardList = [
    {
      key: 1,
      CardTitle: userName.email,
      CardDetails: "Edit your profile",
      cardIcon: ProfileIcon,
      imgColor: "#55c2ed90",
      onClick: () => navigate("/profile"),
      className: "elipsis",
    },
    // {
    //   key: 2,
    //   CardTitle: "Notification",
    //   CardDetails: "Turn on Notification now",
    //   cardIcon: BellIcon,
    //   imgColor: " #fd790575",
    // },
    {
      key: 3,
      CardTitle: "Security & Privacy",
      CardDetails: "Protect yourself from intruders",
      cardIcon: SecurityIcon,
      imgColor: "black",
      onClick: () => navigate("/create-pin"),
    },
    {
      key: 4,
      CardTitle: "Get Help",
      CardDetails: "Get support or send feedback",
      cardIcon: HelpIcon,
      imgColor: " #FD7905",
      onClick: () => navigate("/help"),
    },
    {
      key: 5,
      CardTitle: "Referrals",
      CardDetails: "Get paid when your friends join leverpay today",
      cardIcon: ReferralIcon,
      imgColor: "#DBD8E8",
      onClick: () => navigate("/referral"),
    },
    {
      key: 6,
      CardTitle: "Merchant",
      CardDetails: "Become a merchant today",
      cardIcon: MerchantIcon,
      imgColor: "#F49B09EB",
      onClick: () => {
        setShowMerchantModal(true);
        setTimeout(300);
      },
    },
  ];
  return (
    <div className="settingsPage">
      {/* <div className="settings-page-container">Settings</div> */}
      <div className="settings-container">
        <div className="settings-header">
          <IoIosArrowBack size={20} className="settings-header-back-icon" />
          <span>Settings</span>
          <span></span>
        </div>
        <div className="settings-user-info">
          <div className="user-picture">
            <img
              src={userName.passport || Avatar}
              alt=""
              className="passport"
            />
            <img src={Edit} alt="" className="edit-icon" />
          </div>
          <h2 className={`${!userName.accountNumber ? "padding-bottom" : ""}`}>
            Hello {userName.firstName} {lastInitial}
          </h2>
          {!userName.accountNumber ? (
            ""
          ) : (
            <p>
              Account Number: <span>1234567890</span>
              <img src={Copy} alt="Copy account number" />
            </p>
          )}
        </div>
        <div className="settings-cards-holder">
          {CardList.map((item, i) => (
            <SettingsCard
              key={i}
              title={item.CardTitle}
              className={item.className}
              info={item.CardDetails}
              color={item.imgColor}
              icon={item.cardIcon}
              onClick={item.onClick}
            />
          ))}
        </div>
      </div>
      <div className="settings-footer">
        <span>
          <TbLockFilled
            size={20}
            style={{ color: "white", marginTop: "-7px" }}
          />
        </span>{" "}
        Secured By LeverChain Technology
      </div>
      {showMerchantModal && (
        <MerchantModal onClick={() => setShowMerchantModal(false)} />
      )}
      {/* <GetHelp /> */}
    </div>
  );
};
export default Settings;
